<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >

    <!-- Content Left -->
    <b-col
      class="content-header-left mb-2"
      cols="12"
      md="9"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ $route.meta.pageTitle }}
          </h2>
          <router-link class="nav-link back_link" :to="checkRoute()" v-if="checkUser()"> 
            <b-button type="button" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary"> << </b-button>
          </router-link>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item 
                v-for="item in crumbs"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>

  </b-row>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { isUserLoggedIn, getUserData } from '@/auth/utils'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
  },
 
  computed: {
    crumbs() {
      const crumbs = [];
      if(this.$route.meta.breadcrumb != undefined)
      {
      this.$route.meta.breadcrumb.forEach(item => {
        const crumb = {};
        crumb.text = item.text;
        crumb.active = item.active;
        if (crumb.text == 'Driver Earning') 
        {
          crumb.to = '/apps/payroll/earning';
        } else if (crumb.text == 'Time Sheets') {

          crumb.to = '/apps/reports/time_sheets';
        } else if (crumb.text == 'Tractor List') {

          crumb.to = '/apps/fleet/list';
        } else if (crumb.text == 'Completed Inspections') {

          crumb.to = '/vehicles/inspection_data';
        }
        
        crumbs.push(crumb);
      });
      return crumbs;
    }
    }
  },
  methods: 
  {
    checkRoute()
    {
      if(this.$route.name == 'request-time-off')
      {
        return '/time-clock';
      } else if(this.$route.name == 'clock-history')
      {
        return '/time-clock';
      } else if(this.$route.name == 'clock-history-edit')
      {
        return '/clock-history';
      } else {
        return '/dashboard';
      }
    },

    checkUser() 
    {
      const isLoggedIn = isUserLoggedIn();

      if(isLoggedIn)
      {
        var redirectPath =  getUserData().driver_id == undefined ? false : true;
        return redirectPath;

      } else {

        var redirectPath =  true;
        return redirectPath;

      }

    }

  }
}
</script>
<style scoped>
.back_link {
  float: right;
  padding: 0;
}
</style>
