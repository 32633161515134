export default [
    {
        title: "Company",
        icon: "HomeIcon",
        children: [
            {
                title: "Overview",
                route: "apps-company-overview",
                resource: "manage-company",
                action: "read",
            },
            {
                title: "Connections",
                route: "apps-company-connections",
                resource: "manage-company",
                action: "read",
            },

            {
                title: "Domicile",
                route: "apps-company-domicile-data",
                resource: "manage-company",
                action: "read",
            },
            {
                title: "Routes",
                route: "apps-company-routes",
                resource: "manage-company",
                action: "read",
            },
            // {
            //     title: "Billing",
            //     route: "apps-company-billing",
            //     resource: "manage-company",
            //     action: "read",
            // },
            // {
            //     title: "Domiciles",
            //     route: "apps-company-domiciles",
            //     resource: "manage-company",
            //     action: "read",
            // },
        ],
    },
    {
        title: "Users",
        icon: "UserIcon",
        route: "apps-users-list",
        resource: "manage-user",
        action: "read",
    },
    {
        title: "Tasks",
        // route: "apps-todo",
        route: {
            name: 'apps-todo-filter',
            params: {
                filter: 'pending'
            }
        },
        icon: "CheckSquareIcon",
        resource: "manage-task",
        action: "read",
    },
    {
        title: "KPIs",
        icon: "ClockIcon",
        children: [
            {
                title: "Daily Review",
                route: "apps-kpis-daily",
                resource: "manage-kpi",
                action: "read",
            },
            {
                title: "Weekly Review",
                route: 'apps-kpis-weekly',
                resource: "manage-kpi",
                action: "read",
            }
           
            // {
            //     title: "Revenue",
            //     icon: "DollarSignIcon",
            //     resource: "manage-kpi",
            //     action: "read",
            //     children: [
            //         {
            //             title: "Revenue Per Week",
            //             route: "kpis-revenue-week",
            //             resource: "manage-kpi",
            //             action: "read",
            //         },
            //     ],
            // },
            // {
            //     title: "Miles",
            //     icon: "RepeatIcon",
            //     children: [
            //         {
            //             title: "Miles Per Week By Total",
            //             route: "kpis-miles-total",
            //             resource: "manage-kpi",
            //             action: "read",
            //         },
            //         {
            //             title: "Miles Per Week By Driver",
            //             route: "kpis-miles-driver",
            //             resource: "manage-kpi",
            //             action: "read",
            //         },
            //         {
            //             title: "Miles Per Week By Vehicle",
            //             route: "kpis-miles-vehicle",
            //             resource: "manage-kpi",
            //             action: "read",
            //         },
            //     ],
            // },
            // {
            //     title: "MPG",
            //     icon: "ActivityIcon",
            //     children: [
            //         {
            //             title: "MPG Per Week By Vehicle",
            //             route: "kpis-mpg-week",
            //             resource: "manage-kpi",
            //             action: "read",
            //         },
            //     ],
            // },
            // {
            //     title: "Fuel Cost",
            //     icon: "RefreshCcwIcon",
            //     children: [
            //         {
            //             title: "Cost Per Week By Total",
            //             route: "kpis-fuel-total",
            //             resource: "manage-kpi",
            //             action: "read",
            //         },
            //         {
            //             title: "Cost Per Week By Vehicle",
            //             route: "kpis-fuel-vehicle",
            //             resource: "manage-kpi",
            //             action: "read",
            //         },
            //     ],
            // },
        ],
    },
    {
        title: "Schedule",
        icon: "CalendarIcon",
        children: [
            {
                title: "Calendar",
                route: "apps-calendar",
                resource: "manage-company",
                action: "read",
            },
            {
                title: "Time Off Requests",
                icon: "InboxIcon",
                children: 
                [   
                    {
                        title: "Open Requests",
                        route: "apps-reports-timeoff-open-requests",
                        resource: "manage-roaster",
                        action: "read",
                    },
                    {
                        title: "Completed Requests",
                        route: "apps-reports-timeoff-close-requests",
                        resource: "manage-roaster",
                        action: "read",
                    }
                ]
            }, 
            

        ]
    },
    {
        title: "Roster",
        route: "apps-drivers",
        icon: "UserIcon",
        resource: "manage-roaster",
        action: "read",
    },
    {
        title: "Fleet",
        icon: "TruckIcon",
        children: [
            // {
            //     title: "Vehicle Defects Category",
            //     route: "apps-vehicle-defects-category",
            //     resource: "manage-fleet",
            //     action: "read",
            // },
            {
                title: "Map",
                route: "apps-fleet-map",
                resource: "manage-fleet",
                action: "read",
            },
            {
                title: "Tractor List",
                route: "apps-fleet-list",
                resource: "manage-fleet",
                action: "read",
            },
            {
                title: "Maintenance",
                route: "apps-fleet-maintenance",
                resource: "manage-fleet",
                action: "read",
            },
            {
                title: 'Vehicle Inspections',
                // icon: 'FileTextIcon',
                    children: [
                        {
                            title: "Inspection Forms",
                            route: "apps-vehicle-defects",
                            resource: "manage-fleet",
                            action: "read",
                        },
                        
                        {
                            title: "Completed Inspections",
                            route: "apps-vehicle-inspections",
                            resource: "manage-fleet",
                            action: "read",
                        },
                    ],
            },
          
            
        ],
    }, 

    {
        title: "Settlements",
        icon: "DollarSignIcon",
        route: "apps-settlement-connection",
        resource: "manage-task",
        action: "read",
        // children: [
        //     {
        //         title: "Upload",
        //         route: "apps-settlement-uploading",
        //         resource: "manage-gf-statement",
        //         action: "read",
        //     },
        //     {
        //         title: "Reports",
        //         route: "apps-settlement-connection",
        //         resource: "manage-gf-statement",
        //         action: "read",
        //     },
        // ],
    },

 
    {
        title: "Reports",
        icon: "FileTextIcon",
        children: [    
            {
                title: "IVMR",
                route: "apps-reports-mileage",
                resource: "manage-roaster",
                action: "read",
            },
                  
            {
                title: "MMR",
                route: "apps-reports-mmr",
                resource: "manage-roaster",
                action: "read",
            },
            {
                title: "IFTA",
                route: "apps-reports-ifta",
                resource: "manage-roaster",
                action: "read",
            },
            {
                title: "Time Sheets",
                route: "apps-reports-timesheets",
                resource: "manage-roaster",
                action: "read",
            },   
           
            
           
        ]
    },
    {
        title: "Payroll",
        route: "apps-payroll",
        icon: "CreditCardIcon",
        children: [
            // {
            //     title: "Setting",
            //     route: "apps-payroll-setting",
            //     resource: "manage-payroll-setting",
            //     action: "read",
            // },
            // {
            //     title: "Dispute Earning Report",
            //     route: "apps-dispute-earning-report",
            //     resource: "manage-roaster",
            //     action: "read",
            // },
            {
                title: "Company Payroll",
                route: "apps-payroll-company",
                resource: "manage-payroll",
                action: "read",
            },
            {
                title: "Driver Earning",
                route: "apps-payroll-earning",
                resource: "manage-payroll",
                action: "read",
            },
           
        ],
    },
    {
        title: "Utilities",
        icon: "CommandIcon",
        route: "apps-utilities",
        resource: "manage-utilities",
        action: "read",
    },
    // {
    //     title: "Mileage",
    //     route: "apps-mileage",
    //     icon: "FileTextIcon",
    //     resource: "manage-roaster",
    //     action: "read",
    // },
  
    // {
    //     title: "Weekly Schedule",
    //     route: "apps-schedule-build",
    //     icon: "CalendarIcon",
    //     resource: "manage-schedule",
    //     action: "read",
    // },

   
];
